import ReactGA from 'react-ga4';
import { Location } from 'react-router-dom';

import { GroupedSlot, TopSlot } from '../interfaces/TopSlot';
import { SaveNoSlotParams } from '../interfaces/AvailableNoSlots';
import { EnabledLanguage } from '../interfaces/Language';
import Language from '../helpers/Language';
import { SignatureUpdateParams } from '../interfaces/Signature';
import { Urls } from '../interfaces/Calendar';
import posthog from 'posthog-js';

interface Dimensions {
  dimension1: string; // appointment id
  dimension2: number; // timestamp
  dimension3: number; // session id
}

let isGaInitialized = false;
let initialSource: string | null = null;
let initialReminderVersion: string | null = null;
const dimensions: Partial<Dimensions> = {};

const updateDimensions = <T extends keyof Dimensions>(key: T, value: Dimensions[T]) => {
  dimensions[key] = value;
};

const setAppointmentIdDimension = (id: string | undefined): void => {
  if (id) updateDimensions('dimension1', id);
};

const setTimestampDimension = (): void => {
  updateDimensions('dimension2', Date.now());
};

const setSessionIdDimension = (): void => {
  updateDimensions('dimension3', Date.now());
};

export const initGA = (analyticsId: string): void => {
  ReactGA.initialize(analyticsId, {
    gaOptions: {
      // Initial values for dimensions so they are logged on every event. Dimensions are being overwritten during events
      dimension1: dimensions.dimension1 || 'none',
      dimension2: dimensions.dimension2 || Date.now(),
      dimension3: dimensions.dimension3 || Date.now(),
    },
  });
  isGaInitialized = true;
};

export const sendGAEvent = (category: string, eventName: string, data: unknown = '', nonInteraction = false): void => {

  // TODO: Refactor the code to have all proper checks and naming
  posthog.capture(eventName, {
    eventCategory: category,
    data,
    dataString: typeof data === 'string' ? data : JSON.stringify(data),
    nonInteraction,
    appointmentId: dimensions.dimension1,
    manualTimestamp: dimensions.dimension2,
    manualSessionId: dimensions.dimension3,
  });

  if (!isGaInitialized) {
    return;
  }

  setTimestampDimension();
  ReactGA.event(eventName, {
    event_category: category,
    event_label: typeof data === 'string' ? data : JSON.stringify(data),
    nonInteraction,
    ...dimensions // All the dimensions are logged on every event
  });

};

export const saveInitialSource = (searchString: string): void => {
  const urlParams = new URLSearchParams(searchString);
  initialSource = urlParams.get('s');
  initialReminderVersion = urlParams.get('r');
};

export const sendPageView = (location: Location, prevLocation?: Location): void => {
  if (!isGaInitialized) {
    return;
  }

  setTimestampDimension();

  // https://github.com/codler/react-ga4/blob/master/src/ga4.js#L292
  ReactGA._gaCommandSendPageview(
    location.pathname,
    {
      ...dimensions,
      title: typeof document !== 'undefined' ? document.title : '',
      location: typeof window !== 'undefined' ? window.location.href : '',
      page_referrer: prevLocation?.pathname || ''
    }
  );
};

export const sendSource = (): void => {
  if (!initialSource) {
    initialSource = 'direct';
  }

  if (!initialReminderVersion) {
    initialReminderVersion = '0';
  }

  sendGAEvent('navigation', 'source', { source: initialSource, reminder: initialReminderVersion }, true);
};

const addLanguage = <T extends object>(params: T) => {
  const language: EnabledLanguage = Language.getLanguage();

  return { ...params, l: language };
};

export const setAppointmentId = (id: string | undefined): void => {
  setAppointmentIdDimension(id);
  setSessionIdDimension();
  // since we do not know if we can mutate default events to include appointmentId
  // after initialisation we defer until we first get the appointmentId
  // this has downside of GA not initializing if there is no appointment right now
  initGA(import.meta.env.VITE_GOOGLE_ANALYTICS);
  sendSource();
};

export const setNextStepGA = (step: string): void => {
  sendGAEvent('scheduling', 'next-step', step);
};

export const sendReloadOnSchedulingError = (): void => {
  sendGAEvent('scheduling', 'reload-on-scheduling-error');
};

export const selectedTopSlotIndexGA = (selectedTopSlotIndex: number): void => {
  sendGAEvent('scheduling', 'selected-topslot-index', selectedTopSlotIndex);
};

export const sendAnalyticsLanguageSwitch = (selectedLanguage: EnabledLanguage): void => {
  sendGAEvent('language', 'update-selected-langauge', selectedLanguage);
};

const formatTimeslot = (timeslot: TopSlot) => ({
  g: Number(timeslot.grade.toString().substring(0, 5)),
  s: timeslot.start,
  e: timeslot.end,
});

export const sendAnalyticsSlotsReceived = (slots: GroupedSlot[]): void => {
  if (slots.length) {
    slots.forEach(groupedSlot => {
      if (Array.isArray(groupedSlot.timeslots) && groupedSlot.timeslots.length) {
        groupedSlot.timeslots.forEach(timeslot => {
          const formattedTimeslot = formatTimeslot(timeslot);

          if (Number.isFinite(formattedTimeslot.g)) {
            sendGAEvent('scheduling', 'slots-received', { d: groupedSlot.date, t: formatTimeslot(timeslot) });
          } else {
            sendGAEvent('debug', 'slots-grade-faulty', { faultyValue: timeslot.grade });
          }
        });
      } else {
        sendGAEvent('scheduling', 'slots-received');
      }
    });
  } else {
    sendGAEvent('scheduling', 'slots-received');
  }
};

export const sendAnalyticsDateSelected = (date: string): void => {
  sendGAEvent('scheduling', 'selected-date', date);
};

export const sendDatebarScroll = (direction: 'left' | 'right'): void => {
  sendGAEvent('scheduling', 'datebar-scroll', direction);
};

export const sendDatebarLoadMore = (): void => {
  sendGAEvent('scheduling', 'datebar-load-more');
};

export const sendAnalyticsNoSlots = (params: SaveNoSlotParams): void => {
  // TODO remove params.comment, the params are not used by Data team that it is fine atm
  // however this payload gets cut due to 100 char limit and is useless as is
  sendGAEvent('scheduling', 'save-no-slots', { ...addLanguage(params) });
};

export const successfullyBookedAppointmentGA = (topSlot: TopSlot): void => {
  sendGAEvent('scheduling', 'successfully-booked', { ...addLanguage(formatTimeslot(topSlot)) });
};

export const sendAnalyticsNoSlotsWithError = (requestsWithError: number): void => {
  sendGAEvent('scheduling', 'get-no-slots-with-error', { requestsWithError });
};

export const sendChatRequested = (page: string): void => {
  sendGAEvent('support', 'chat-requested', { page });
};

export const sendFAQstring = (value: string): void => {
  sendGAEvent('support', 'faq-search', { value });
};

export const sendSubmitPersonalDetailsClicked = (): void => {
  sendGAEvent('customer', 'save-details-clicked');
};

export const sendRescheduleClicked = (): void => {
  sendGAEvent('scheduling', 'reschedule-appointment-clicked');
};

export const sendCancelAppointmentClicked = (): void => {
  sendGAEvent('scheduling', 'cancel-appointment-clicked');
};

export const sendConfirmShown24Hours = (): void => {
  sendGAEvent('navigation', 'confirm-shown-24-hours');
};

export const sendCalendarOpened = (): void => {
  sendGAEvent('scheduling', 'add-to-calendar-open');
};

export const sendCalendarSelected = (value: keyof Urls): void => {
  sendGAEvent('scheduling', 'add-to-calendar-select', value);
};

export const sendDifferenceInDaysSelectedInCalendar = (days: number): void => {
  sendGAEvent('scheduling', 'difference-days-requested-slots', days);
};

export const sendSignatureDetailsUpdated = (
  params: Pick<SignatureUpdateParams, 'customerSigned'> & { imageSize?: number }
): void => {
  sendGAEvent('signature', 'report-signed', { ...params });
};

export const sendRetry = (retry: number | undefined, url: string): void => {
  sendGAEvent('debug', 'retry-request', { retry, url });
};

export const sendException = (error: Error): void => {
  sendGAEvent('exception', 'error', {
    fatal: true,
    description: error.toString()
  });
};
