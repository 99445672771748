import React from 'react';
import { createRoot } from 'react-dom/client';

import { AppRoot } from './App';
import './helpers/i18n';
import { saveInitialSource } from './services/Analytics';
import { PostHogProvider } from 'posthog-js/react';

saveInitialSource(document.location.search);

const options = {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
};

createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <AppRoot />
    </PostHogProvider>
  </React.StrictMode>,
);
